export const seoModel = {
        page_title: "",
        title: "",
        description: "",
        robots: "",
        index: "",
        keywords: "",
        url: "",
        footer_title: "",
        footer_description: "",
        script: "",
        header_description: "",
        path: "",
        twitter: {
          title: "",
          description: "",
        },
        open_graph: {
          title: "",
          description: "",
        },
}