import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Sidebar.css";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { MdOutlinePermMedia, MdOutlineRealEstateAgent } from "react-icons/md";
import { IoEarthOutline } from "react-icons/io5";
import { BiMapPin } from "react-icons/bi";
import { GiModernCity } from "react-icons/gi";
import { IoLocationOutline } from "react-icons/io5";
import { HiOutlineCash } from "react-icons/hi";

const Sidebar = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openDropdownDew, setOpenDropdownDew] = useState(false);
  const [openDropdownOther, setOpenDropdownOther] = useState(true);
  const openDropdownHandler = (index) => {
    if(index == 0){
      setOpenDropdown(!openDropdown)
    }
    if(index == 1){
      setOpenDropdownDew(!openDropdownDew)
    }
    if(index == 2){
      setOpenDropdownOther(!openDropdownOther)
    }
  };

  const sidebar = [
    {
      title: "Propularity",
      icon: <img src="https://propularity-bucket.s3.ap-south-1.amazonaws.com/image-1707820070893.png" alt="propularity logo" />,
      dropdown: [
        { link: "/builder-projects", icon: <HiOutlineBuildingOffice className="icon" />, label: "Builder Projects" },
        { link: "/priority", icon: <HiOutlineBuildingOffice className="icon" />, label: "Projects Priority" },
        { link: "/priority-india", icon: <HiOutlineBuildingOffice className="icon" />, label: "Projects India" },
        { link: "/top-projects", icon: <HiOutlineBuildingOffice className="icon" />, label: "Top Projects" },
        { link: "/builder-priority", icon: <HiOutlineBuildingOffice className="icon" />, label: "Builder Priority" },
        { link: "/plans-priority", icon: <HiOutlineBuildingOffice className="icon" />, label: "Plans Priority" },
        { link: "/priority-microlocation", icon: <IoLocationOutline className="icon" />, label: "Location Priority" },
        { link: "/seo", icon: <MdOutlineRealEstateAgent className="icon" />, label: "SEO" },
        { link: "/clients", icon: <MdOutlineRealEstateAgent className="icon" />, label: "Our Clients" },
        { link: "/media", icon: <MdOutlinePermMedia className="icon" />, label: "Media" },
      ]
    },
    {
      title: "Dwarkaexpressway",
      icon: <img src="https://dwarkaexpressway-bucket.s3.ap-south-1.amazonaws.com/images/fb7507856243c4164f32ff1a198c186aa016dcc4.png" alt="propularity logo" />,
      dropdown: [
        { link: "/dwarkaexpressway/builder-projects", icon: <HiOutlineBuildingOffice className="icon" />, label: "Builder Projects" },
        { link: "/dwarkaexpressway/all-urls", icon: <HiOutlineCash className="icon" />, label: "Create Url" },  
        // { link: "/dwarkaexpressway/priority-india", icon: <HiOutlineBuildingOffice className="icon" />, label: "Projects India" },
        { link: "/dwarkaexpressway/top-projects", icon: <HiOutlineBuildingOffice className="icon" />, label: "Top Projects" },
        { link: "/dwarkaexpressway/affordable-housing", icon: <HiOutlineBuildingOffice className="icon" />, label: "Affordable Housing" },
        { link: "/dwarkaexpressway/new-launch-projects", icon: <HiOutlineBuildingOffice className="icon" />, label: "New Launch Projects" },
        { link: "/dwarkaexpressway/plans-priority", icon: <HiOutlineBuildingOffice className="icon" />, label: "Plans Priority" },
         { link: "/dwarkaexpressway/resi-comm-priority", icon: <HiOutlineBuildingOffice className="icon" />, label: "Projects Type Priority" },
        { link: "/dwarkaexpressway/top-builders", icon: <HiOutlineBuildingOffice className="icon" />, label: "Top Builders" },
        { link: "/dwarkaexpressway/seo", icon: <MdOutlineRealEstateAgent className="icon" />, label: "SEO" },
        { link: "/dwarkaexpressway/all-newlaunch", icon: <HiOutlineBuildingOffice className="icon" />, label: "Create New Launch Ad" },
        { link: "/dwarkaexpressway/media", icon: <MdOutlinePermMedia className="icon" />, label: "Media" },
        // { link: "/dwarkaexpressway/builder-priority", icon: <HiOutlineBuildingOffice className="icon" />, label: "Builder Priority" },
        // { link: "/dwarkaexpressway/priority-microlocation", icon: <IoLocationOutline className="icon" />, label: "Location Priority" }
      ]
    },
    {
      title: "Other",
      icon: null,
      dropdown: [
        { link: "/builder", icon: <MdOutlineRealEstateAgent className="icon" />, label: "Builder" },
        { link: "/builder-plan", icon: <HiOutlineBuildingOffice className="icon" />, label: "Project Types" },
        { link: "/country", icon: <IoEarthOutline className="icon" />, label: "Country" },
        { link: "/state", icon: <BiMapPin className="icon" />, label: "State" },
        { link: "/city", icon: <GiModernCity className="icon" />, label: "City" },
        { link: "/microlocation", icon: <IoLocationOutline className="icon" />, label: "Location" },
        { link: "/amenities", icon: <HiOutlineCash className="icon" />, label: "Amenities" }
      ]
    }
  ];

  const renderDropdownMenu = (dropdown) => {
    return dropdown.map((item, index) => (
      <li key={index} className="nav-item">
        <NavLink to={item.link} className="dropdown-item">
          {item.icon}
          {item.label}
        </NavLink>
      </li>
    ));
  };

  const renderSidebarItems = () => {
    return sidebar.map((item, index) => (
      <div key={index}>
        <button
          className="btn btn-secondary dropdown_btn"
          type="button"
          onClick={() => openDropdownHandler(index)}
          style={{ top: index === 1 ? "71" : "" }}
        >
          {item.icon}
          {item.title}
          {index === 0 ? (openDropdown ? <IoIosArrowDown className="arrow_icon" /> : <IoIosArrowForward className="arrow_icon" />) : (openDropdownDew ? <IoIosArrowDown className="arrow_icon" /> : <IoIosArrowForward className="arrow_icon" />)}
        </button>
        {(index === 0 && openDropdown) || (index === 1 && openDropdownDew) || (index === 2 && openDropdownOther) ? (
          <ul className="light_border">
            {renderDropdownMenu(item.dropdown)}
          </ul>
        ) : null}
      </div>
    ));
  };

  return (
    <div className="sidebar">
      <div className="sidenav">
        <div className="nav-menu-wrapper">
          {renderSidebarItems()}
          {/* <ul className="p-0">
            {renderDropdownMenu(sidebar[2].dropdown)}
          </ul> */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
