export const project = {
    name: "",
    slug: "",
    builder: "",
    project_type: "residential",
    starting_price: "",
    configuration: "",
    project_status: "",
    project_size: "",
    ratings: "",
    tagline: "",
    project_tag: "",
    coming_soon: "",
    short_descrip: "",
    video: "",
    for_rent: false,
    for_sale: true,
    is_rera_approved: false,
    is_zero_brokerage: false,
    seo: {
      title: "",
      description: "",
      robots: "noindex, nofollow",
      index: false,
      keywords: "",
      script: "",
      twitter: {
        title: "",
        description: "",
      },
      open_graph: {
        title: "",
        description: "",
      },
    },
    location: {
      address: "",
      country: "",
      state: "",
      city: "",
      micro_location: [],
      latitude: "",
      longitude: "",
      latitude2: "",
      longitude2: "",
      metro_detail: {
        name: "",
        is_near_metro: false,
      }
    },
    plans:[{
      id: 1,
      category: "",
      size: "",
      size_sq: "Sq.Ft.",
      price: "",
      image: "",
      floor_plans: [{
        id: 1,
        name: "",
        area: "",
        rent_price: "",
        sale_price: "",
        image: "",
    }],
    }],
    images: [{
      image: "",
      name: "",
      alt: "",
      order: 0,
    }],
    allAmenities: {
      commercial: [],
      residential: [],
  },
  master_plan: "",
  brochure: "",
  highlights: "",
  description: "",
  plans_type: "",
  contact_details: [
    {
      id: 1,
      user: "",
      email: "",
      phone: "",
      designation: "",
    },
  ],
  advantages: "",
  location_map: "",
  features: "",
  banner_bullets: "",
  }