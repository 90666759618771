import React, { useEffect, useState } from "react";
import Mainpanelnav from "../mainpanel-header/Mainpanelnav";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { BiSkipNext, BiSkipPrevious } from "react-icons/bi";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import Select from "react-select";
import BASE_URL from "../../apiConfig";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  getTopProjectsByPlanType,
  getProjectsDataByPlanAndCity,
  changePlanOrderOfProjects,
  changePlanOrderOfProjectsByDrag,
} from "./PlansPriorityService";
import { getCity } from "../builders/BuilderService";
import { getPropertyTypes } from "./PlansPriorityService";
import { getProjectData } from "../builder-projects/ProjectService";
function BuilderPriority() {
  const [loading, setLoading] = useState(false);
  const [projects, setprojects] = useState([]);
  const [updateTable, setUpdateTable] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedprojects, setSearchedprojects] = useState([]);
  const [showAll, setShowAll] = useState(true);
  const [planTypes, setPlanTypes] = useState([]);
  const [selectedPlanType, setSelectedPlanType] = useState(null);
  const [priorityprojects, setPriorityprojects] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [totalCount, setTotalCount] = useState(0)
  const [query, setQuery] = useState({ name: "", city: "", status: "approve", page: 1, limit: 10, plans_type: "" })
  const [cities, setCities] = useState([]);
  const url = window.location.href;
  const [mounted, setMounted] = useState(false);
  const handleFetchTypes = async () => {
  const data =  await getPropertyTypes();
  setPlanTypes(data)
  };
  const handleFetchCity = async () => {
    await getCity(setCities);
  };
  const getProjectDataWithPagination = async () => {
    setLoading(true)
    const data = await getProjectData(query, url)
    setprojects(data?.projects)
    setTotalCount(data?.totalCount)
    setLoading(false)
  }
  const handleFetchTopProjects = async (planTypeId, cityId) => {
    setLoadingTable(true)
    const data = await getTopProjectsByPlanType(planTypeId, cityId, url)
    setPriorityprojects(data)
    setLoadingTable(false)
  };
  const onChangeOptionHandler = (selectedOption, dropdownIdentifier) => {
    switch (dropdownIdentifier) {
      case "PlanType":
        setSelectedPlanType(selectedOption);
        query.city = selectedCity?.value;
        query.plans_type = selectedOption ? selectedOption.value : null;
        getProjectDataWithPagination();
        handleFetchTopProjects(
          selectedOption ? selectedOption.value : null, selectedCity?.value
        );
        break;
     case "city":
          setSelectedCity(selectedOption);
          break;
      default:
        break;
    }
  };
  const cityOptions = cities?.map((city) => ({
    value: city._id,
    label: city.name,
  }));
  const allPlans = ["655f4a500bb98dbecc38ad75","6501889687a793abe11b9090", "6501889f87a793abe11b9095", "6501887887a793abe11b9081", "65018a3c87a793abe11b90a0", "6501860d87a793abe11b8fdb", '6501861387a793abe11b8fe0', '650185fc87a793abe11b8fd1', '650185ef87a793abe11b8fcc', '6501887e87a793abe11b9086', '6501888d87a793abe11b908b', "6530b4079c394a3277094380"]
const filteredPlanType = planTypes.filter(item => {
    return allPlans.includes(item._id)
});
  const planTypesOptions = filteredPlanType?.map((type) => ({
    value: type._id,
    label: type.name,
  }));
  useEffect(() => {
    if(query.name || query.limit > 10 || query.page > 1) {
     setMounted(true);
    }
   }, [query.name, query.limit, query.page]);
  useEffect(() => {
    if (mounted && (query.name || query.limit > 10 || query.page > 1)) {
      setLoading(true);
      const debounceTimer = setTimeout(() => {
        getProjectDataWithPagination();
      }, 1000);
      return () => clearTimeout(debounceTimer);
    }
    if (mounted && query.name === "") {
      getProjectDataWithPagination();
    }
  }, [mounted, query.name, query.limit, query.page]);
  useEffect(() => {
    setprojects([])
    setPriorityprojects([])
    setSelectedCity({value: "", label: "City*"})
    setSelectedPlanType({value: "", label: "PlanTypes*"})
    setQuery({ name: "", city: "", location: "", status: "approve", page: 1, limit: 10, plans_type: "" })
    handleFetchCity();
    handleFetchTypes();
   }, [url]);
   const lastIndex = query.page * query.limit;
   const firstIndex = lastIndex - query.limit;
   const nPage = Math.ceil(
     totalCount / query.limit
   );
   if (firstIndex > 0) {
    var prePage = () => {
      if (query.page !== firstIndex) {
        setQuery({...query, page: query.page-1});
      }
    };
  }

  var nextPage = () => {
    const lastPage = Math.ceil(
      totalCount / query.limit
    );
    if (query.page < lastPage) {
      setQuery({...query, page: query.page+1});
    }
  };
  const getFirstPage = () => {
    setQuery({...query, page: 1});
  };
  const getLastPage = () => {
    setQuery({...query, page: nPage});
  };  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQuery({
      ...query,
      [name]: value,
    });
  };
  const handleCheckboxChange = async (event, project) => {
    const { checked } = event.target;
    try {
      const selectedPlanTypeId = selectedPlanType?.value;
      const updatedproject = {
        order: checked
          ? projects.filter((project) => project.plans_priority.is_active == true)
              .length + 1
          : 1000,
        plans_type: selectedPlanTypeId,
        is_active: checked,
        cityId: selectedCity?.value,
      };
      await changePlanOrderOfProjects(updatedproject, project._id, url)
      project.plans_priority.is_active = checked;
      setprojects([...projects]);
      handleFetchTopProjects(selectedPlanTypeId,selectedCity?.value);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const onDragEnd = async (result) => {
    const { destination, source } = result;
    if (!destination) return;
    if (destination.index === source.index) return;
    const reorderedprojects = Array.from(priorityprojects);
    const [movedproject] = reorderedprojects.splice(source.index, 1);
    reorderedprojects.splice(destination.index, 0, movedproject);
    const updatedOrderPayload = reorderedprojects.map((project, index) => ({
      _id: project._id,
      plans_priority: {
        order: index + 1,
        plans_type: selectedPlanType?.value
      },
    }));
    setPriorityprojects(reorderedprojects);
    try {
     await changePlanOrderOfProjectsByDrag(updatedOrderPayload, url)
    } catch (error) {
      console.error("Error updating priority order:", error);
    }
  };
  return (
    <div className="mx-5 mt-3">
      <Mainpanelnav />
      <div className="table-box table_top_header">
        <div className="table-top-box">Projects Priority by Builder Module</div>
        <div className="row my-5">
        <div className="col-md-3">
            <Select
              placeholder="City*"
              value={selectedCity}
              options={cityOptions}
              onChange={(selectedOption) =>
                onChangeOptionHandler(selectedOption, "city")
              }
              isSearchable
              required
            />
          </div>
          <div className="col-md-3">
            <Select
              placeholder="PlanTypes*"
              value={selectedPlanType}
              options={planTypesOptions}
              onChange={(selectedOption) =>
                onChangeOptionHandler(selectedOption, "PlanType")
              }
              isSearchable
              required
            />
          </div>
        </div>
      </div>
      <div className="table_container">
        <div className="table-box top_table_box1">
          <div className="table-top-box">Projects Module</div>
          <TableContainer style={{ overflowX: "hidden" }}>
            <div className="row search_input">
              <div className="col-md-3">
              <div className="form-floating border_field">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Search by name"
                    value={query.name}
                    name="name"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="floatingInput">Search by name</label>
                </div>
              </div>
            </div>
            <div className="data_table">
              <div className="row">
                <div className="col-md-12">
                  <Table variant="simple" className="table_border">
                    <Thead>
                      <Tr className="table_heading_row">
                        <Th>Select</Th>
                        <Th>Name</Th>
                        <Th>MicroLocation</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {loading ? (
                        <Tr>
                          <Td colSpan={8} textAlign="center">
                            <Spinner size="lg" />
                          </Td>
                        </Tr>
                      ) : projects.map((project) => (
                            <Tr key={project._id}>
                              <Td>
                                <input
                                  type="checkbox"
                                  checked={project.plans_priority.is_active}
                    onChange={(event) =>
                                    handleCheckboxChange(event, project)
                                  }
                                />
                              </Td>
                              <Td>
                                {project?.name}
                              </Td>
                              <Td>
                                {project.location?.micro_location
                                  ? project.location?.micro_location[0]?.name
                                  : ""}
                              </Td>
                            </Tr>
                          ))
                      
                      }
                      {/* {(!loading && !((showAll ? projects : searchedprojects)
  .slice((curPage - 1) * selectItemNum, curPage * selectItemNum).length)) && (
  <Tr>
    <Td colSpan={8}>No matching results found.</Td>
  </Tr>
)} */}
                    </Tbody>
                  </Table>
                </div>
              </div>
            </div>
          </TableContainer>
          <nav className="mt-5">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: "70%" }}
          >
            <p className="mb-0">Items per page: </p>
            <div style={{ borderBottom: "1px solid gray" }}>
              <select
                className="form-select"
                aria-label="Default select example"
                name="limit"
                value={query.limit}
                onChange={handleInputChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div style={{ width: "110px" }}>
              {firstIndex + 1} -{" "}
              {projects.length + firstIndex}
              {" "}
              of {totalCount}
            </div>
            <div className="page-item">
              <BiSkipPrevious onClick={getFirstPage} />
            </div>
            <div className="page-item">
              <GrFormPrevious onClick={prePage} />
            </div>
            <div className="page-item">
              <GrFormNext onClick={nextPage} />
            </div>
            <div className="page-item">
              <BiSkipNext onClick={getLastPage} />
            </div>
          </div>
        </nav>
        </div>
        <div className="table-box top_table_box2">
          <div className="table-top-box">Priority Projects Module</div>
          <TableContainer style={{ overflowX: "hidden" }}>
            <div className="data_table">
              <div className="row">
                <div className="col-md-12">
                  <Table variant="simple" className="table_border">
                    <Thead>
                      <Tr>
                        <Th>Order</Th>
                        <Th>Name</Th>
                      </Tr>
                    </Thead>

                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="projectss">
                        {(provided) => (
                          <Tbody
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {loadingTable ? (
                              <Tr>
                                <Td colSpan={3} textAlign="center">
                                  <Spinner size="lg" />
                                </Td>
                              </Tr>
                            ) : (
                              priorityprojects.map((project, index) => (
                                <Draggable
                                  key={project._id}
                                  draggableId={project._id}
                                  index={index}
                                >
                                  {(provided) => (
                                    <Tr
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                    >
                                      <Td {...provided.dragHandleProps}>
                                        {index + 1}
                                      </Td>
                                      <Td {...provided.dragHandleProps}>
                                        {project?.name}
                                      </Td>
                                    </Tr>
                                  )}
                                </Draggable>
                              ))
                            )}
                            {provided.placeholder}
                          </Tbody>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </Table>
                </div>
              </div>
            </div>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

export default BuilderPriority;
